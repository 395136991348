<template>
    <div class="btn btn-secondary btn-sm">
        <div v-if="loaderActive" class="loader">
			<spinner :size="21" :colors="spinnerColor" />
		</div>
		<template v-else>
			<div class="text">{{text}}</div>
		</template>
    </div>
</template>

<script>
    import Spinner from "@/components/loaders/Spinner";
    export default {
        name: "SmallButton",
        components: {Spinner},
        props: {
            text: String,
            loaderActive: Boolean,
            route: {
                type: String,
                default: ""
            }
        },
        computed: {
            spinnerColor(){
				if(this.type === 'success')
					return 'green-darkest';
				
				return 'blue-darkest';
			}
        }
    }

</script>

<style lang="scss" scoped>
	.text{
		white-space: nowrap;
	}
</style>
