<template>
	<div class="field-select" :class="classes">
		<div class="form__group field">
			<select class="form__field" :id="id" :name="name" ref="select" :value="value" @change="updateValue()" :disabled='disabled' >
				<option v-for="(option,index) in options" :key="index" :value="option.value" :selected="option.value === value ? 'selected' : '' ">{{option.text}}</option>
			</select>
			<label :for="id" class="form__label">{{label}}</label>
			<div v-if="typeof error !== 'undefined'" class="error">{{error}}</div>
		</div>
	</div>
</template>

<script>
	let uuid = 0;
	export default {
		name: "FieldSelect",
		data(){
			return {
				id: ''
			}
		},
		methods: {
			updateValue(){
				this.$emit('input',!isNaN(this.$refs.select.value) ? Number(this.$refs.select.value) : this.$refs.select.value );
			}
		},
		props: {
			value: String|Number,
			error: String,
			options: Array,
			label: String,
			name: String,
			classes: {
                default: 'col-md-6',
				type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
		},
		created() {
			this.id = 'field-select-'+uuid.toString();
			uuid += 1;
		}
	};
</script>

<style lang="scss" scoped>
	.field-select{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			input{
				border-bottom: 1px solid $danger;
			}
		}
		select{
			display: block;
			::placeholder{
				color: $lightGray;
			}
		}
		.error{
			color: $danger;
            padding-left: 0px;
            font-size: 0.625rem;
		}
	}

    .form__group {
        position: relative;
        padding: 15px 0 0;
        margin-top: 0px;
    }

    .form__field {
        font-family: inherit;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $lighterGray;
        outline: 0;
        font-size: 0.875rem;
        padding: 7px 0;
        background: transparent;
		transition: border-color 0.2s;
		-webkit-appearance: none;

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown ~ .form__label {
            font-size: 0.875rem;
            cursor: text;
            top: 20px;
        }
    }

    .form__label {
        position: absolute;
        top: 5px;
        display: block;
        transition: 0.2s;
        font-size: 0.625rem;
        color: $lightGray;
        pointer-events: none;
    }

    .form__field:focus {
        ~ .form__label {
            position: absolute;
            top: 5px;
            display: block;
            transition: 0.2s;
            font-size: 0.625rem;
        }
        padding-bottom: 7px;
    }
    /* reset input */
    .form__field{
        &:required,&:invalid { box-shadow:none; }
    }
    .form__field:disabled {
        color: $lightGray;
	    -webkit-text-fill-color: #93a3ab;
	    -webkit-opacity: 1;
    }
</style>
